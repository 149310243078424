
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import { store } from './store';
import { EntityStoreGetters } from '@/base';

@Component
export default class CashBook extends SubModule
{
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM]
  {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    return [];
  }
}
